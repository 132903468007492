/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQuery } from "./BaseQuery";
import { endpoints } from "static";

const { USERS } = endpoints;

export const usersQuery = createApi({
  reducerPath: "users",
  keepUnusedDataFor: 3600,
  baseQuery: BaseQuery,
  endpoints: (builder) => ({
    getuserListing: builder.query<any, any>({
      query: () => ({ url: USERS, method: "get" }),
    }),
  }),
});

export const { useGetuserListingQuery } = usersQuery;
