import moment from "moment";
export const DataFormeter = (values: any) => {
  const Flight = values?.trip_flight.map((flight: any) => {
    const {
      depart_acode_id = null,
      depart_aname = "",
      depart_city = null,
      depart_country = null,
      depart_date = "",
      depart_time_pref_id = null,
      dest_acode_id = null,
      dest_aname = "",
      dest_city = null,
      dest_country = null,
      return_date = "",
      return_time_pref_id = null,
      flight_pref_id = null,
      time_pref_id = null,
      world_airline_id = null,
      description = "",
      world_airline_name = "",
      world_airline_country_id = "",
      world_airline_iata_code = "",
    } = flight;
    return {
      depart_acode_id: { label: depart_aname, value: depart_acode_id },
      depart_aname,
      depart_city,
      depart_country,
      depart_date: depart_date && moment(depart_date),
      depart_time_pref_id,
      dest_acode_id: { label: dest_aname, value: dest_acode_id },
      dest_aname,
      dest_city,
      dest_country,
      return_date: return_date && moment(return_date),
      return_time_pref_id,
      flight_pref_id,
      time_pref_id,
      description,
      world_airline_id: { label: world_airline_name, value: world_airline_id },
      world_airline_name,
      world_airline_country_id,
      world_airline_iata_code,
    };
  });
  const Hotal = values?.trip_hotels.map((hotel: any) => {
    const {
      name = "",
      city = null,
      country = null,
      checkin_date = "",
      checkin_time = "",
      checkout_date = "",
      checkout_time = "",
      description = "",
    } = hotel;
    return {
      name,
      city: { label: hotel?.city_name, value: city },
      country: { label: hotel?.country_name, value: country },
      checkin_date: checkin_date && moment(checkin_date),
      checkin_time: checkin_time && moment(checkin_time),
      checkout_date: checkout_date && moment(checkout_date),
      checkout_time: checkout_time && moment(checkout_time),
      description,
    };
  });

  const Car = values?.trip_car_rents.map((car: any) => {
    const {
      pickup_place_name = "",
      pickup_city = null,
      pickup_country = null,
      pickup_date = "",
      pickup_time = "",
      dropoff_place_name = "",
      dropoff_city = null,
      dropoff_country = null,
      dropoff_date = "",
      dropoff_time = "",
      car_type_id = null,
      with_driver = false,
      description = "",
    } = car;
    return {
      pickup_place_name,
      pickup_city: { label: car?.pickup_city_name, value: pickup_city },
      pickup_country: { label: car?.pickup_country_name, value: pickup_country },
      pickup_date: pickup_date && moment(pickup_date),
      pickup_time: pickup_time && moment(pickup_time),
      dropoff_place_name,
      dropoff_city: { label: car?.dropoff_city_name, value: dropoff_city },
      dropoff_country: { label: car?.dropoff_country_name, value: dropoff_country },
      dropoff_date: dropoff_date && moment(dropoff_date),
      dropoff_time: dropoff_time && moment(dropoff_time),
      car_type_id,
      with_driver: !!+with_driver,
      description,
    };
  });

  const Bus = values?.trip_bus_rents.map((bus: any) => {
    const {
      depart_place_name = "",
      depart_city = null,
      depart_country = null,
      depart_date = "",
      arrive_place_name = "",
      arrive_city = null,
      arrive_country = null,
      description = "",
    } = bus;
    return {
      depart_place_name,
      depart_city: { label: bus?.depart_city_name, value: depart_city },
      depart_country: { label: bus?.depart_country_name, value: depart_country },
      depart_date: depart_date && moment(depart_date),
      arrive_place_name,
      arrive_city: { label: bus?.arrive_city_name, value: arrive_city },
      arrive_country: { label: bus?.arrive_country_name, value: arrive_country },
      description,
    };
  });

  const Train = values?.trip_train_rents.map((train: any) => {
    const {
      depart_place_name = "",
      depart_city = null,
      depart_country = null,
      depart_date = "",
      arrive_place_name = "",
      arrive_city = null,
      arrive_country = null,
      description = "",
    } = train;
    return {
      depart_place_name,
      depart_city: { label: train?.depart_city_name, value: depart_city },
      depart_country: { label: train?.depart_country_name, value: depart_country },
      depart_date: depart_date && moment(depart_date),
      arrive_place_name,
      arrive_city: { label: train?.arrive_city_name, value: arrive_city },
      arrive_country: { label: train?.arrive_country_name, value: arrive_country },
      description,
    };
  });

  const Ferry = values?.trip_ferry_rents.map((ferry: any) => {
    const {
      depart_place_name = "",
      depart_city = null,
      depart_country = null,
      depart_date = "",
      arrive_place_name = "",
      arrive_city = null,
      arrive_country = null,
      description = "",
    } = ferry;
    return {
      depart_place_name,
      depart_city: { label: ferry?.depart_city_name, value: depart_city },
      depart_country: { label: ferry?.depart_country_name, value: depart_country },
      depart_date: depart_date && moment(depart_date),
      arrive_place_name,
      arrive_city: { label: ferry?.arrive_city_name, value: arrive_city },
      arrive_country: { label: ferry?.arrive_country_name, value: arrive_country },
      description,
    };
  });

  const DataById = {
    name: values?.name,
    travel_type: values?.travel_type,
    is_visa_require: values?.is_visa_require === 0 ? false : true,
    business_purpose: values?.business_purpose,
    dest_country_id: { label: values?.country?.country_name, value: values?.country?.id },
    trip_flights: {
      trip_type_id: values?.trip_type_id,
      seat_pref_id:
        values?.trip_flight &&
        values?.trip_flight.length > 0 &&
        values?.trip_flight[0]?.seat_pref_id,
      meal_pref_id: {
        label: values?.trip_flight[0]?.meal_preference?.name,
        value: values?.trip_flight[0]?.meal_preference?.id,
      },
      flight_details: Flight,
    },
    trip_hotels: Hotal,
    trip_car_rents: Car,
    trip_bus_rents: Bus,
    trip_train_rents: Train,
    trip_ferry_rents: Ferry,
  };

  return DataById;
};
