/** @format */
import { MouseEventHandler } from "react";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { Icons } from ".";
import { Labels } from "static";

const { YES, NO } = Labels;
const { AiOutlineEdit, RiDeleteBinLine, BsEye } = Icons;

type Iprops = {
  data: any;
  title: string;
  organization_id?: number;
  handleClick: MouseEventHandler | ((id: number) => void);
  handleConfirm: MouseEventHandler | ((id: number) => void);
  handleViewtClick?: (data: any) => void;
  view?: boolean;
  disableEdit?: boolean;
};

export const RenderAction = ({
  data,
  title,
  organization_id,
  handleClick,
  handleConfirm,
  handleViewtClick = () => null,
  view = false,
  disableEdit = false,

}: Iprops) => {
  return (
    <>
      <Space>
        {view && (
          <Tooltip title="View">
            <Button
              shape="circle"
              icon={<BsEye />}
              onClick={() => handleViewtClick(data.id)}
              disabled={!organization_id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Tooltip>
        )}
        <Tooltip title="Edit">
          <Button
            shape="circle"
            icon={<AiOutlineEdit />}
            onClick={() => handleClick(data.id)}
            disabled={!organization_id || disableEdit}
          />
        </Tooltip>
        <Popconfirm
          key="confirm"
          okText={YES}
          cancelText={NO}
          placement="left"
          title={title}
          onConfirm={() => handleConfirm(data.id)}
          disabled={!organization_id}
        >
          <Button key="deletebtn" shape="circle" disabled={!organization_id}>
            <RiDeleteBinLine size={15} />
          </Button>
        </Popconfirm>
      </Space>
    </>
  );
};
