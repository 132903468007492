import { useState, useCallback } from "react"
export const useDetailPage = () => {
    const [detail, setDetail] = useState()
    const [fullscreen, setFullScreen] = useState(false)

    const handleFullScreen = useCallback((fullScreen: boolean) => setFullScreen(fullScreen), []);


    const handleViewClick = (record: any, listing: any) => {

        const { id } = record
        const current = listing.findIndex((item: { id: number }) => {
            return item.id === id
        })

        let obj = {
            currentid: listing[current].id,
            prev_id: listing[current - 1]?.id || null,
            next_id: listing[current + 1]?.id || null,
            module: "customer",
            fullscreen: fullscreen
        }

        localStorage.setItem("obj", JSON.stringify(obj))
        setDetail(record)
        !fullscreen && setFullScreen(true)
    }


    return { handleViewClick, detail, fullscreen, handleFullScreen }

};

